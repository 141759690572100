import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDateUs',
  standalone: true,
})
export class FormatDatePipe implements PipeTransform {
  transform(dateString: string | null): string {
    if (!dateString) return '';
    const [year, month, day] = dateString.split('-');
    return `${month}-${day}-${year}`;
  }
}
