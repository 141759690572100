import { Injectable, signal } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UnsubscribeBase } from '../base';

@Injectable()
export class TrackBreakpointService extends UnsubscribeBase {
  isMobileScreen = signal<boolean>(false);
  isTabletScreen = signal<boolean>(false);

  constructor(private breakpointObserver: BreakpointObserver) {
    super();
    this.trackMobileBreakpoint();
    this.trackTableBreakpoint();
  }

  trackMobileBreakpoint() {
    this.addSubscription = this.breakpointObserver
      .observe([`(max-width: 576px)`])
      .subscribe((result) => {
        this.isMobileScreen.set(result.matches);
      });
  }

  trackTableBreakpoint() {
    this.addSubscription = this.breakpointObserver
      .observe([`(max-width: 1100px)`])
      .subscribe((result) => {
        this.isTabletScreen.set(result.matches);
      });
  }
}
