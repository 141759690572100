interface DateObject {
  year: number;
  month: number;
  day: number;
}

interface DatepickerConstants {
  MIN_DATE: DateObject;
  MAX_DATE: DateObject;
  MAX_DATE_WITH_AGE_LIMIT: DateObject;
  WEEKDAYS_FORMAT: DatepickerWeekdaysFormat;
  AGE_LIMIT: number;
  CURRENT_DATE: Date;
  MIN_DATE_DASHBOARD: DateObject;
}

interface I18nValues {
  weekdays: string[];
  months: string[];
  weekLabel: string;
}

type DatepickerWeekdaysFormat = boolean | 'short' | 'long' | 'narrow';

const CURRENT_DATE: Date = new Date();
const MIN_DATE: DateObject = {
  year: CURRENT_DATE.getFullYear() - 100,
  month: CURRENT_DATE.getMonth() + 1,
  day: CURRENT_DATE.getDate(),
};
const MAX_DATE: DateObject = {
  year: CURRENT_DATE.getFullYear(),
  month: CURRENT_DATE.getMonth() + 1,
  day: CURRENT_DATE.getDate(),
};
const MAX_DATE_WITH_AGE_LIMIT: DateObject = {
  year: CURRENT_DATE.getFullYear() - 21,
  month: CURRENT_DATE.getMonth() + 1,
  day: CURRENT_DATE.getDate(),
};

const MIN_DATE_DASHBOARD: DateObject = {
  year: CURRENT_DATE.getFullYear() - 1,
  month: CURRENT_DATE.getMonth() + 1,
  day: CURRENT_DATE.getDate(),
};

export const DATEPICKER_CONSTANTS: DatepickerConstants = {
  MIN_DATE,
  MAX_DATE,
  MAX_DATE_WITH_AGE_LIMIT,
  WEEKDAYS_FORMAT: 'short',
  AGE_LIMIT: 21,
  CURRENT_DATE,
  MIN_DATE_DASHBOARD,
};

export const DATEPICKER_TIME_FORMAT = 'M/d/yyyy H:mm:ss';

export const I18N_DATEPICKER_VALUES: { [key: string]: I18nValues } = {
  fr: {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: [
      'Jan',
      'Fév',
      'Mar',
      'Avr',
      'Mai',
      'Juin',
      'Juil',
      'Aou',
      'Sep',
      'Oct',
      'Nov',
      'Déc',
    ],
    weekLabel: 'sem',
  },
  en: {
    weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    months: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    weekLabel: 'wk',
  },
};
