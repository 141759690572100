import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Injectable()
export class FormValidationService {
  scrollToFirstInvalidControl(form: FormGroup) {
    const firstInvalidControl: HTMLElement = document.querySelector(
      'form .ng-invalid',
    ) as HTMLElement;
    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      firstInvalidControl.focus();
      this.markAllAsTouched(form);
    }
  }

  private markAllAsTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).forEach((key) => {
      const control = group.get(key);
      if (control instanceof FormControl) {
        control.markAsTouched();
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        this.markAllAsTouched(control);
      }
    });
  }
}
