import { Directive, HostListener, Input } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[appCustomTooltip]',
  providers: [NgbTooltip],
  standalone: true,
})
export class CustomTooltipDirective {
  @Input('appCustomTooltip') tooltipText = '';

  constructor(private tooltip: NgbTooltip) {}

  @HostListener('mouseenter')
  onMouseEnter() {
    this.tooltip.ngbTooltip = this.tooltipText;
    this.tooltip.open();
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.tooltip.close();
  }

  @HostListener('click')
  onClick() {
    this.tooltip.close();
  }
}
