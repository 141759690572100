<a
  [href]="queryUrl"
  target="_blank"
  [ngClass]="{
    'text-gray-600 text-hover-primary': !isButton,
    'btn btn-sm btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap gap-3':
      isButton,
  }"
>
  <ng-content></ng-content>
</a>
