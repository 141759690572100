import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'core-map-link',
  standalone: true,
  imports: [NgIf, NgClass],
  templateUrl: './map-link.component.html',
  styleUrl: './map-link.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapLinkComponent implements OnInit {
  private readonly API_QUERY =
    'https://www.google.com/maps/search/?api=1&query=';
  queryUrl = '';
  @Input() latitude = 0;
  @Input() longitude = 0;
  @Input() isButton = false;

  ngOnInit() {
    this.queryUrl = `${this.API_QUERY}${this.latitude},${this.longitude}`;
  }
}
